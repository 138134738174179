import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { LoginComponent } from './login/login.component';
import { InfoColaboradorComponent } from './info-colaborador/info-colaborador.component';
import { PanelHomeComponent } from './panel/home/home.component';
import { MenuAdminComponent } from './panel/menu-admin/menu-admin.component';
import { UsuariosComponent } from './panel/usuarios/usuarios.component';
import { TipoComidaComponent } from './panel/tipo-comida/tipo-comida.component';
import { CargaMasivaComponent } from './panel/usuarios/carga-masiva/carga-masiva.component';
import { ReportesComponent } from './panel/reportes/reportes.component';
import { AppRoutingModule } from './app.routing';
import { AuthGuard } from "../app/guards/AuthGuard";
import { JwtModule } from '@auth0/angular-jwt';
import { DataTablesModule } from 'angular-datatables';
import { ThemeConstants } from './@espire/shared/config/theme-constant';
import { ModuloSpinerLoginComponent } from './shared/modulo-spiner-login/modulo-spiner-login.component';
import {  NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModuloModalMensajeComponent } from './shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { ModuloModalAdvertenciaComponent } from './shared/modulo-modal-advertencia/modulo-modal-advertencia.component';
import { ModuloMensajesComponent } from './shared/modulo-mensajes/modulo-mensajes.component';
import { UsuariosAdminComponent } from './panel/usuarios-admin/usuarios-admin.component';
import { AuthService } from 'src/services/auth.service';
import { ModuloCambioContrasenaComponent } from './login/modulo-cambio-contrasena/modulo-cambio-contrasena.component';

/* jwt getToken */
export function obtenerToken(): string {
  return localStorage.getItem("jwt");
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    LoginComponent,
    InfoColaboradorComponent,
    PanelHomeComponent,
    MenuAdminComponent,
    UsuariosComponent,
    TipoComidaComponent,
    CargaMasivaComponent,
    ReportesComponent,
    ModuloSpinerLoginComponent,
    ModuloModalMensajeComponent,
    ModuloModalAdvertenciaComponent,
    ModuloMensajesComponent,
    UsuariosAdminComponent,
    ModuloCambioContrasenaComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    DataTablesModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: obtenerToken,
        //  allowedDomains: ["localhost:44394"],
        //  disallowedRoutes: ["localhost:44394"],
            allowedDomains: ["comedoresapi.duranm.me"],
            disallowedRoutes: ["https://comedoresapi.duranm.me/"],
      },
    }),
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
  ],
  entryComponents: [
    ModuloModalMensajeComponent
  ],
  providers: [AuthGuard, ThemeConstants, AuthService],
  bootstrap: [AppComponent],
})
export class AppModule { }
