import { NgModule } from "@angular/core";

import { Routes, RouterModule } from "@angular/router";
import { CounterComponent } from "./counter/counter.component";
import { FetchDataComponent } from "./fetch-data/fetch-data.component";

// Layouts

import { AuthGuard } from "./guards/AuthGuard";
import { HomeComponent } from "./home/home.component";
import { InfoColaboradorComponent } from "./info-colaborador/info-colaborador.component";
import { LoginComponent } from "./login/login.component";
import { ModuloCambioContrasenaComponent } from "./login/modulo-cambio-contrasena/modulo-cambio-contrasena.component";
import { PanelHomeComponent } from "./panel/home/home.component";
import { ReportesComponent } from "./panel/reportes/reportes.component";
import { TipoComidaComponent } from "./panel/tipo-comida/tipo-comida.component";
import { UsuariosAdminComponent } from "./panel/usuarios-admin/usuarios-admin.component";
import { CargaMasivaComponent } from "./panel/usuarios/carga-masiva/carga-masiva.component";
import { UsuariosComponent } from "./panel/usuarios/usuarios.component";

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', component: InfoColaboradorComponent, pathMatch: 'full' },
  { path: 'panel/home', component: PanelHomeComponent },
  { path: 'panel/colaboradores', component: UsuariosComponent, },
  { path: 'panel/colaboradores/carga-masiva', component: CargaMasivaComponent },
  { path: 'panel/usuarios-sistema', component: UsuariosAdminComponent },
  { path: 'panel/tipo-comida', component: TipoComidaComponent },
  { path: 'panel/reportes/general', component: ReportesComponent },
  { path: 'panel/reportes/por-comida', component: ReportesComponent },
  { path: 'recuperar-contrasena/:token', component: ModuloCambioContrasenaComponent },
  { path: "**", redirectTo: "inicio" },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
