import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { Lista_Conf_horario_tipo_comida_modelo_response, Lista_tipo_comidas_modelo_response } from 'src/model/Catalogos/TipoComida';
import { ServiceGenerico } from 'src/services/service-generico.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { arr_Img_Colaborador, Colaborador_modelo_response, Lista_colaboradores_modelo_response } from 'src/model/Catalogos/Colaboradores';
import { Lista_horarios_usuario_modelo_request, Lista_horarios_usuario_modelo_response } from 'src/model/Catalogos/Horarios';
import { Lista_cpostal_modelo_response, Lista_estado_modelo_response, Lista_municipio_modelo_response } from 'src/model/Catalogos/Generales';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
  providers: [ServiceGenerico]
})
export class UsuariosComponent implements OnInit {
  @ViewChild("modalAddColaborador", { static: false }) modalAddColaborador: TemplateRef<any>;
  @ViewChild("modalConfHorarioColaborador", { static: false }) modalConfHorarioColaborador: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarColaborador: NgbModalRef;
  modalConfiguracionHorarioColaborador: NgbModalRef;
  modalSuspenderColaborador: NgbModalRef;

  formGroupAgregarColaborador: FormGroup;
  formGroupAgregarColaboradorDireccion: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
public archivos: any = [];
public usuarioId: string = "";

  

  private modelo_configuracion: ServiciosRutas;
  listaColaboradores: Lista_colaboradores_modelo_response[];
  Colaborador: Colaborador_modelo_response;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  _tbl_usuario_id: number;
  pinEnable: boolean = false;
  listaTipoComidas: Array<Lista_tipo_comidas_modelo_response> = [];
  listaTipoComidasFiltrado: Array<Lista_tipo_comidas_modelo_response> = [];
  _nombreCola: string;
  listaHorariosUsuario: Lista_horarios_usuario_modelo_response[];
  listaCpostal: Lista_cpostal_modelo_response[];
  listaMunicipio: Lista_municipio_modelo_response[];
  listaEstado: Lista_estado_modelo_response[];
  disabledBtnasighorario: boolean = true;
  
  imagenPrevia: string;
  nombreImg: string;
  files: any = []
  loading: boolean;
  nuevaImagenAvatar: boolean = false;
  // _rel_horario_tipo_comida_id: number;

  constructor(
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
   }

  ngOnInit() {
    this.inicializaTabla();
    this.obtenerColaboradores();
    this.inicializarFormAgregarColaborador();
    // this.inicializarFormConfHorarioTipoComida();

  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  obtenerColaboradores() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Colaboradores/GetListaColaboradores?p_estatus=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaColaboradores = [] = tempdate.response as Lista_colaboradores_modelo_response[];
            this.renderTabla();
          } else {
            this.listaColaboradores = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarColaborador() {
    this.formGroupAgregarColaborador = this.fb.group({
      p_nombre: ["", Validators.required],
      p_apellido_paterno: ["", Validators.required],
      p_apellido_materno: [""],
      p_correo: [""],
      p_telefono: [""],
      p_curp: [""],
      p_fecha_nacimiento: [""],
      p_numero_empleado: ["", Validators.required],
      p_es_alergico: [0],
      p_requiere_pin: [0],
      p_pin: [0],
    });

    this.formGroupAgregarColaboradorDireccion = this.fb.group({
      p_b_direccion: [""],
      p_calle: [""],
      p_numero_ext: [""],
      p_numero_int: [""],
      p_cpostal: [0],
      p_cat_cpostal_id: [0],
      p_cat_municipio_id: [0],
      p_cat_estado_id: [0],
    });
  }

  agregarColaborador() {
    this.funcionalidad = 'Nuevo';
    this._tbl_usuario_id = 0;
    this.openModalFormColaborador(true);
  }

  GuardarColaborador() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarColaborador.value;
    // let model = this.formGroupAgregarColaboradorDireccion.value;
    model.p_tbl_usuario_id = this._tbl_usuario_id;

    if (model.p_fecha_nacimiento == null) {
      model.p_fecha_nacimiento = "";
    }
    if ((this.formGroupAgregarColaboradorDireccion.value.p_calle == "" || this.formGroupAgregarColaboradorDireccion.value.p_calle == null) &&
    (this.formGroupAgregarColaboradorDireccion.value.p_numero_ext == "" || this.formGroupAgregarColaboradorDireccion.value.p_numero_ext == null) &&
    (this.formGroupAgregarColaboradorDireccion.value.p_numero_int == ""|| this.formGroupAgregarColaboradorDireccion.value.p_numero_int == null) &&
    (this.formGroupAgregarColaboradorDireccion.value.p_cpostal == "" ||this.formGroupAgregarColaboradorDireccion.value.p_cpostal == null) &&
    (this.formGroupAgregarColaboradorDireccion.value.p_cat_cpostal_id == "" || this.formGroupAgregarColaboradorDireccion.value.p_cat_cpostal_id == null ) &&
    (this.formGroupAgregarColaboradorDireccion.value.p_cat_municipio_id == "" || this.formGroupAgregarColaboradorDireccion.value.p_cat_municipio_id == null ) &&
    (this.formGroupAgregarColaboradorDireccion.value.p_cat_estado_id == "" || this.formGroupAgregarColaboradorDireccion.value.p_cat_estado_id == null )) 
    {
     model.p_b_direccion = this.formGroupAgregarColaboradorDireccion.value.p_b_direccion = 0;
     model.p_calle = '';
     model.p_numero_ext = '';
     model.p_numero_int = '';
     model.p_cpostal = 0;
     model.p_cat_cpostal_id = 0;
     model.p_cat_municipio_id = 0;
     model.p_cat_estado_id = 0;
    } else {
     model.p_b_direccion = this.formGroupAgregarColaboradorDireccion.value.p_b_direccion = 1;

     model.p_calle =this.formGroupAgregarColaboradorDireccion.value.p_calle == null? "" : this.formGroupAgregarColaboradorDireccion.value.p_calle;
     model.p_numero_ext =this.formGroupAgregarColaboradorDireccion.value.p_numero_ext == null? "" : this.formGroupAgregarColaboradorDireccion.value.p_numero_ext;
     model.p_numero_int =this.formGroupAgregarColaboradorDireccion.value.p_numero_int == null? "" : this.formGroupAgregarColaboradorDireccion.value.p_numero_int;
     model.p_cpostal =this.formGroupAgregarColaboradorDireccion.value.p_cpostal == null? 0 : this.formGroupAgregarColaboradorDireccion.value.p_cpostal;
     model.p_cat_cpostal_id =this.formGroupAgregarColaboradorDireccion.value.p_cat_cpostal_id == null? 0 : this.formGroupAgregarColaboradorDireccion.value.p_cat_cpostal_id;
     model.p_cat_municipio_id =this.formGroupAgregarColaboradorDireccion.value.p_cat_municipio_id == null? 0 : this.formGroupAgregarColaboradorDireccion.value.p_cat_municipio_id;
     model.p_cat_estado_id =this.formGroupAgregarColaboradorDireccion.value.p_cat_estado_id == null? 0 : this.formGroupAgregarColaboradorDireccion.value.p_cat_estado_id;
    }

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Colaboradores/PostColaborador")
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerColaboradores();
            //subimos la imagen de perfil
            if (this.imagenPrevia) {
              this.subirImgPerfil(); 
            }
            //subimos la imagen de perfil
            this.modalAgregarColaborador.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Colaboradores/PutColaborador")
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerColaboradores();
            if (this.imagenPrevia && this.nombreImg != '') {
              this.subirImgPerfil(); 
            }
            this.modalAgregarColaborador.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    }
  }

  onChangePIN(valuePIN) {
    if (valuePIN == 1) {
      this.pinEnable = true;
      this.formGroupAgregarColaborador.value.p_pin = '';
      this.formGroupAgregarColaborador.patchValue(this.formGroupAgregarColaborador.value);
    } else {
      this.pinEnable = false;
    }
  }

  verDetalleColaborador(tbl_usuario_id){
    this.esEditable = false;
    this.openModalFormColaborador(false);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Colaboradores/GetColaborador_x_id?p_tbl_usuario_id=" + tbl_usuario_id)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Colaborador = tempdate.response as Colaborador_modelo_response;
            this.formGroupAgregarColaborador.value.p_nombre = this.Colaborador[0].nombre;
            this.formGroupAgregarColaborador.value.p_apellido_paterno = this.Colaborador[0].apellido_paterno;
            this.formGroupAgregarColaborador.value.p_apellido_materno = this.Colaborador[0].apellido_materno;
            this.formGroupAgregarColaborador.value.p_correo = this.Colaborador[0].correo;
            this.formGroupAgregarColaborador.value.p_telefono = this.Colaborador[0].telefono;
            this.formGroupAgregarColaborador.value.p_curp = this.Colaborador[0].curp;
            this.formGroupAgregarColaborador.value.p_fecha_nacimiento = (this.Colaborador[0].fecha_nacimiento) == null? this.Colaborador[0].fecha_nacimiento : (this.Colaborador[0].fecha_nacimiento).split('T')[0] ;
            this.formGroupAgregarColaborador.value.p_numero_empleado = this.Colaborador[0].numero_empleado;
            this.formGroupAgregarColaborador.value.p_es_alergico = this.Colaborador[0].es_alergico? 1 : 0;
            this.formGroupAgregarColaborador.value.p_requiere_pin = this.Colaborador[0].requiere_pin? 1 : 0;
            this.onChangePIN(this.formGroupAgregarColaborador.value.p_requiere_pin);
            this.formGroupAgregarColaborador.value.p_pin = this.Colaborador[0].pin;
            this.formGroupAgregarColaborador.value.p_tbl_direccion_id = this.Colaborador[0].tbl_direccion_id;
            this.formGroupAgregarColaboradorDireccion.value.p_calle = this.Colaborador[0].calle;
            this.formGroupAgregarColaboradorDireccion.value.p_numero_ext = this.Colaborador[0].numero_ext;
            this.formGroupAgregarColaboradorDireccion.value.p_numero_int = this.Colaborador[0].numero_int;
            this.formGroupAgregarColaboradorDireccion.value.p_cpostal = this.Colaborador[0].cpostal;
            this.formGroupAgregarColaboradorDireccion.value.p_cat_cpostal_id = this.Colaborador[0].cat_cpostal_id;
            this.formGroupAgregarColaboradorDireccion.value.p_cat_municipio_id = this.Colaborador[0].cat_municipio_id;
            this.formGroupAgregarColaboradorDireccion.value.p_cat_estado_id = this.Colaborador[0].cat_estado_id;
            this.formGroupAgregarColaboradorDireccion.value.p_nombre_pais = this.Colaborador[0].nombre_pais;
            this.formGroupAgregarColaborador.patchValue(this.formGroupAgregarColaborador.value);
            this.formGroupAgregarColaboradorDireccion.patchValue(this.formGroupAgregarColaboradorDireccion.value);
            this.consultaImagenPerfil(this.Colaborador[0].numero_empleado.toString());
          } else {
            this.listaColaboradores = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );  
    }

    editarColaborador(tbl_usuario_id) {
      this.funcionalidad = 'Editar';
      this._tbl_usuario_id = tbl_usuario_id;
      this.openModalFormColaborador(true);
      this.operacionRespuesta.EstaEjecutando = true;
      this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Colaboradores/GetColaborador_x_id?p_tbl_usuario_id=" + tbl_usuario_id)
        .subscribe(
          (tempdate) => {
            if (tempdate) {
              this.Colaborador = tempdate.response as Colaborador_modelo_response;
              this.formGroupAgregarColaborador.value.p_nombre = this.Colaborador[0].nombre;
              this.formGroupAgregarColaborador.value.p_apellido_paterno = this.Colaborador[0].apellido_paterno;
              this.formGroupAgregarColaborador.value.p_apellido_materno = this.Colaborador[0].apellido_materno;
              this.formGroupAgregarColaborador.value.p_correo = this.Colaborador[0].correo;
              this.formGroupAgregarColaborador.value.p_telefono = this.Colaborador[0].telefono;
              this.formGroupAgregarColaborador.value.p_curp = this.Colaborador[0].curp;
              this.formGroupAgregarColaborador.value.p_fecha_nacimiento = (this.Colaborador[0].fecha_nacimiento) == null? this.Colaborador[0].fecha_nacimiento : (this.Colaborador[0].fecha_nacimiento).split('T')[0] ;
              this.formGroupAgregarColaborador.value.p_numero_empleado = this.Colaborador[0].numero_empleado;
              this.formGroupAgregarColaborador.value.p_es_alergico = this.Colaborador[0].es_alergico? 1 : 0;
              this.formGroupAgregarColaborador.value.p_requiere_pin = this.Colaborador[0].requiere_pin? 1 : 0;
              this.onChangePIN(this.formGroupAgregarColaborador.value.p_requiere_pin);
              this.formGroupAgregarColaborador.value.p_pin = this.Colaborador[0].pin;
              this.formGroupAgregarColaborador.value.p_tbl_direccion_id = this.Colaborador[0].tbl_direccion_id;
              this.formGroupAgregarColaboradorDireccion.value.p_calle = this.Colaborador[0].calle;
              this.formGroupAgregarColaboradorDireccion.value.p_numero_ext = this.Colaborador[0].numero_ext;
              this.formGroupAgregarColaboradorDireccion.value.p_numero_int = this.Colaborador[0].numero_int;
              this.formGroupAgregarColaboradorDireccion.value.p_cpostal = this.Colaborador[0].cpostal;
              this.formGroupAgregarColaboradorDireccion.value.p_cat_cpostal_id = this.Colaborador[0].cat_cpostal_id;
              this.formGroupAgregarColaboradorDireccion.value.p_cat_municipio_id = this.Colaborador[0].cat_municipio_id;
              this.formGroupAgregarColaboradorDireccion.value.p_cat_estado_id = this.Colaborador[0].cat_estado_id;
              this.formGroupAgregarColaboradorDireccion.value.p_nombre_pais = this.Colaborador[0].nombre_pais;
              this.formGroupAgregarColaborador.patchValue(this.formGroupAgregarColaborador.value);
              this.formGroupAgregarColaboradorDireccion.patchValue(this.formGroupAgregarColaboradorDireccion.value);
              this.consultaImagenPerfil(this.Colaborador[0].numero_empleado.toString());
            } else {
              this.listaColaboradores = [];
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }

    eliminarColaborador(p_tbl_usuario_id) {
      this.operacionRespuesta.EstaEjecutando = true;
      this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Colaboradores/DeleteColaborador?p_tbl_usuario_id=" + p_tbl_usuario_id.usuarioId)
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerColaboradores();
              this.modalSuspenderColaborador.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }
  openModalFormColaborador(esEditable:boolean) {
    this.esEditable = esEditable;
    this.onChangePIN(0);
    this.formGroupAgregarColaborador.reset({p_nombre:'', p_apellido_paterno:'', p_apellido_materno:'',p_correo:'', p_telefono:'',
    p_curp:'', p_fecha_nacimiento:'',p_numero_empleado:0, p_es_alergico:0,p_requiere_pin:0, p_pin:0});
    
    this.formGroupAgregarColaboradorDireccion.reset({
    p_b_direccion:'', p_calle:'',p_numero_ext:'', p_numero_int:'',p_cpostal:0, p_cat_cpostal_id:0, p_cat_municipio_id:0,p_cat_estado_id:0});
    
    this.nuevaImagenAvatar = false;
    $("#upload").val('');
    this.imagenPrevia = '';
    this.nombreImg = '';

    this.modalAgregarColaborador = this.modalService.open(this.modalAddColaborador, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg'  });
  }

  openModalConfHorarioColaborador(tbl_usuario_id, nombre, apellido) {
    this.obtenerHorariosUsuario(tbl_usuario_id);
    this.obtenerTipoComidas();
    this._nombreCola = nombre + " " + apellido;
    this.modalConfiguracionHorarioColaborador = this.modalService.open(this.modalConfHorarioColaborador, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });

  }

  obtenerTipoComidas() {
    this.listaTipoComidasFiltrado = [];
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/TipoComidas/GetListaTipoComidas?p_estatus=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaTipoComidas = [] = tempdate.response as Lista_tipo_comidas_modelo_response[];
            this.listaTipoComidas.forEach(item => {
              if(item.rel_horario_tipo_comida_id != null){
                this.listaTipoComidasFiltrado.push(item);
              }
            });


          } 
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  obtenerHorariosUsuario(tbl_usuario_id) {
    this._tbl_usuario_id = tbl_usuario_id;
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/ConfiguracionHorario/GetConfHorarioUsuarios?p_tbl_usuario_id=" + tbl_usuario_id)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaHorariosUsuario = tempdate.response as Lista_horarios_usuario_modelo_response[];
           } else {
            this.listaHorariosUsuario = [];
           }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  agregarHorario(value) {
    
    let model = new Lista_horarios_usuario_modelo_request;
    var _p_rel_horario_tipo_comida_id = $('#p_rel_horario_tipo_comida_id').val();
    model.p_rel_usuario_horario_id = 0;
    model.p_rel_horario_tipo_comida_id = _p_rel_horario_tipo_comida_id.toString();
    model.p_usuario_id = this._tbl_usuario_id;
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/ConfiguracionHorario/PostConfHorarioUsuarios")
    .subscribe(
      (tempdate) => {
        if (tempdate.response[0].success == 1) {
          this.openMensajes(tempdate.response[0].msg, false, '');
          this.obtenerHorariosUsuario(this._tbl_usuario_id);
        } else {
          this.openMensajes(tempdate.response[0].msg, true, '');
        }
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async (err) => {
        this.openMensajes("No se pudo realizar la acción", true, '');
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );
}

onChangeTipoComidaSelect(valor){
  if (valor == 0) {
    this.disabledBtnasighorario = true;
  } else {
    this.disabledBtnasighorario = false;
  }
}

  
quitarHorario(rel_usuario_horario_id) {
  this.operacionRespuesta.EstaEjecutando = true;
  this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/ConfiguracionHorario/DeleteUsuarioHorario?p_rel_usuario_horario_id=" + rel_usuario_horario_id)
    .subscribe(
      (tempdate) => {
        if (tempdate.response[0].success == 1) {
          this.openMensajes(tempdate.response[0].msg, false, '');
          this.obtenerHorariosUsuario(this._tbl_usuario_id);
        } else {
          this.openMensajes(tempdate.response[0].msg, true, '');
        }
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async (err) => {
        this.openMensajes("No se pudo realizar la acción", true, '');
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );
}


getColonias(cpostal) {
  this.operacionRespuesta.EstaEjecutando = true;
  this.services
    .HttpGet(
      this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListacpostal?p_cpostal=" + cpostal)
    .subscribe(
      (tempdate) => {
        if (tempdate) {
          this.listaCpostal = tempdate.response as Lista_cpostal_modelo_response[];
          this.getMunicipio(this.listaCpostal[0].cat_municipio_id);
          this.getEstado(this.listaCpostal[0].cat_estado_id);
        } 
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async (err) => {
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );
}

getMunicipio(cat_municipio_id) {
  this.operacionRespuesta.EstaEjecutando = true;
  this.services
    .HttpGet(
      this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaMunicipio?p_cat_municipio_id=" + cat_municipio_id)
    .subscribe(
      (tempdate) => {
        if (tempdate) {
          this.listaMunicipio = tempdate.response as Lista_municipio_modelo_response[];
                } 
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async (err) => {
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );
}

getEstado(cat_estado_id) {
  this.operacionRespuesta.EstaEjecutando = true;
  this.services
    .HttpGet(
      this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaEstado?p_cat_estado_id=" + cat_estado_id)
    .subscribe(
      (tempdate) => {
        if (tempdate) {
          this.listaEstado = tempdate.response as Lista_estado_modelo_response[];
                } 
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async (err) => {
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );
}


  capturarFile(event: any) {
    this.files = [];
    const imagen = event.target.files[0];
    console.log(imagen);
    if (imagen.type.includes('image')) {
      this.files.push(imagen)
      this.blobFile(imagen).then((res: any) => {
        this.imagenPrevia = res.base;
        this.nombreImg = res.blob.name;
        this.nuevaImagenAvatar = true;
      })

      // this.loadImages();
    } else {
      this.openMensajes("El archivo no corresponde a una imagen", true, '');
    }
  }

  blobFile = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          blob: $event,
          image,
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          blob: $event,
          image,
          base: null
        });
      };

    } catch (e) {
      return null;
    }
  })

  subirImgPerfil() {
    let modelImg = new arr_Img_Colaborador();
    modelImg.imgName = this.formGroupAgregarColaborador.value.p_numero_empleado.toString();
    modelImg.archivo = this.imagenPrevia;
    modelImg.extension = this.nombreImg.split('.')[1];
    // modelImg.numero_empleado = this.formGroupAgregarColaborador.value.p_numero_empleado;
    this.services.HttpPost(modelImg, this.modelo_configuracion.serviciosOperaciones + "/Colaboradores/PostImgColaborador")
    .subscribe(
      (tempdate) => {
        if (tempdate.response[0].success == 1) {
          // this.openMensajes(tempdate.response[0].msg, false, '');
        } else {
          this.openMensajes(tempdate.response[0].msg, true, '');
        }
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async (err) => {
        this.openMensajes("No se pudo realizar la acción", true, '');
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );
  }

  consultaImagenPerfil(nombreImg: string ){
    this.imagenPrevia = "";
    // this.esEditable = false;
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/InfoUsuario/GetImagenPerfil?imgName=" + nombreImg)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].nombre != "") {
            this.imagenPrevia = "data:image/jpeg;base64," + tempdate.response[0].nombre;
            this.nuevaImagenAvatar = true;
          } else {
            this.nuevaImagenAvatar = false;
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.nuevaImagenAvatar = false;
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );  
  }

openDesactivar(tbl_usuario_id) {
  this.usuarioId = tbl_usuario_id;
  this.modalSuspenderColaborador = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
}

  openMensajes(Mensaje: string, Error: boolean,titulo:string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, {ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Colaborador';
  
    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }
}
